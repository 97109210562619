import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AmmuseLogo from '../images/ammuse-logo.svg'

const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
`
const Text = styled.span`
  font-family: 'Anonymous Pro', sans-serif;
  font-size: 13px;
  color: ${props => props.color};
  line-height: 14px;

  & a {
    color: inherit;
    box-sizing: content-box;
    display: inline-block;
  }
`
const StyledAmmuseLogo = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 5px;
`

const Credits = ({ color, backgroundColor }) => (
  <Container backgroundColor={backgroundColor}>
    <Text color={color}>Създадено от</Text>
    <a href="http://ammuse.com" target="_blank" rel="noopener noreferrer">
      <StyledAmmuseLogo src={AmmuseLogo} alt="Ammuse logo" />
    </a>
  </Container>
)

Credits.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
}

Credits.defaultProps = {
  color: '#818181',
  backgroundColor: '#fff',
}

export default Credits
