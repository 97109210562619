import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { ThemeProvider, theme } from 'util/style'
import Header from 'components/Header'
import { Footer } from 'components/Footer'
import { Box } from 'components/Grid'
import CookieConsent from 'components/CookieConsent'

import config from '../../../config/meta'

const Layout = ({ footerData = null, headerData = null, children }) => (
  <ThemeProvider theme={theme}>
    <Box>
      <Header
        siteTitle={config.siteTitle || config.siteTitleAlt}
        data={headerData}
      />
      {children}
      <Footer footerData={footerData} headerData={headerData} />
      <CookieConsent />
    </Box>
  </ThemeProvider>
)

Layout.propTypes = {
  headerData: PropTypes.object.isRequired,
  footerData: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout

export const query = graphql`
  fragment LayoutFragment on Query {
    footerData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "footer" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            tagline
            socialLinks {
              label
              linkURL
            }
          }
        }
      }
    }
    headerData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "header" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            menuItems {
              label
              linkType
              linkURL
              submenu {
                label
                linkType
                linkURL
              }
            }
          }
        }
      }
    }
  }
`
