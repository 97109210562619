import React from 'react'
import PropTypes from 'prop-types'

import { Navbar } from 'components/Navbar'

const Header = ({ data }) => <Navbar data={data} />

Header.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Header
