import * as styledComponents from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { theme } from './theme'

// Utility function to get pixel values from a unitless scale in our custom theme
const themePx = (...args) => props => `${themeGet(...args)(props)}px`

const media = Object.keys(theme.bpAliases).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${theme.bpAliases[label]}) {
      ${css(...args)}
    }
  `
  return acc
}, {})

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents

export {
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  theme,
  themeGet,
  themePx,
  media,
}

export default styled
