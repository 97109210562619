import styled, { themeGet, media } from 'util/style'
import { Flex, Box, Container } from 'components/Grid'
import CustomLink from 'components/CustomLink'

export { Container, CustomLink }

export const Wrapper = styled(Flex)`
  height: 112px;
`
export const LogoLink = styled(CustomLink)`
  & > img {
    vertical-align: middle;
  }
`
export const StyledNav = styled(Box)``

export const MobileMenu = styled(Box)`
  display: none;
  ${media.tablet`display: block;`}
`

export const BottomLine = styled.div`
  background-color: #d5d5d5;
  width: 100%;
  height: 1px;
`

export const StyledLogo = styled.img`
  width: 80px;
  height: 80px;
`
export const StyledMenu = styled(Flex)`
  ${media.tablet`
    background: ${themeGet('colors.white')};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    opacity: ${({ isVisible }) => (isVisible ? 0.95 : 0)};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
  `}

  transition: all 0.2s ease;
`
export const StyledMenuItem = styled(Box)`
  display: flex;
  position: relative;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  ${media.tablet`
    text-transform: uppercase;
  `}

  /* Links */
  & > a,
  & > a:visited {
    color: ${themeGet('colors.textColor')};
    font-weight: 600;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
  }

  & > a:hover,
  & > a:focus {
    color: ${themeGet('colors.primary')};
  }

  /* Sub-menu arrow */
  & a > svg {
    position: absolute;
    width: 9px;
    top: 9px;
    right: 11px;
  }

  & a > svg g {
    fill: ${themeGet('colors.grey.500')};
  }
`
