import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { theme, media } from 'util/style'

import logo from 'images/logo.svg'
// import ArrowDown from 'images/arrow-down.inline.svg'
import {
  Container,
  StyledNav,
  StyledLogo,
  StyledMenu,
  StyledMenuItem,
  BottomLine,
  Wrapper,
  LogoLink,
  CustomLink,
} from './styles'
import HamburgerButton from './HamburgerButton'

const Hamburger = styled(HamburgerButton)`
  display: none;
  ${media.tablet`display: block;`}
`

class NavbarTemplate extends Component {
  state = { open: false }

  handleClick = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render = () => {
    const {
      data: { menuItems },
    } = this.props
    const { open } = this.state

    return (
      <Container as="header" maxWidth="1150px">
        <Wrapper
          justifyContent="space-between"
          alignItems="center"
          px={[3, 3, 0]}
        >
          <LogoLink linkType="internal" linkURL="/">
            <StyledLogo src={logo} alt="Logo" />
          </LogoLink>
          <StyledNav as="nav">
            <Hamburger
              isOpen={open}
              menuClicked={this.handleClick}
              animationDuration={0.2}
              color={theme.colors.textColor}
              width={28}
              height={18}
              strokeWidth={2}
              rotate={0}
              borderRadius={1}
            />
            {menuItems.length > 0 && (
              <StyledMenu
                as="ul"
                isVisible={open}
                m={0}
                flexDirection={['column', 'column', 'row']}
                justifyContent="center"
                alignItems="center"
              >
                {menuItems.map(menuItem => (
                  <StyledMenuItem
                    as="li"
                    key={menuItem.linkURL}
                    flexDirection={['column']}
                    justifyContent="center"
                    px={[0, 0, 3]}
                    py={[3, 3, 0]}
                    m={0}
                  >
                    <CustomLink
                      linkType={menuItem.linkType}
                      linkURL={menuItem.linkURL}
                    >
                      {menuItem.label}
                      {/* {menuItem.submenu &&  <ArrowDown />} */}
                    </CustomLink>
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            )}
          </StyledNav>
        </Wrapper>
        <BottomLine />
      </Container>
    )
  }
}

NavbarTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

const Navbar = ({ data }) => {
  if (!data) {
    return null
  }
  return <NavbarTemplate data={data} />
}
Navbar.propTypes = {
  data: PropTypes.object,
}
Navbar.defaultProps = {
  data: [],
}

export { Navbar }
