import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import Credits from 'components/Credits'
import CustomLink from 'components/CustomLink'
import {
  Box,
  StyledContainer,
  Branding,
  Menu,
  MenuItem,
  StyledLogo,
  Info,
  Heading,
  Tagline,
  Wrapper,
} from './styles'

import logo from '../../images/logo.svg'

const MenuTemplate = ({ menu, linkType }) => {
  return menu.length > 0 ? (
    <Menu as="ul" flexDirection="column">
      {menu.map(item => (
        <MenuItem as="li" key={shortid.generate()}>
          <CustomLink href={item.linkURL} linkType={linkType}>
            {item.label}
          </CustomLink>
        </MenuItem>
      ))}
    </Menu>
  ) : null
}

MenuTemplate.propTypes = {
  menu: PropTypes.array.isRequired,
  linkType: PropTypes.string,
}

MenuTemplate.defaultProps = {
  linkType: 'internal',
}

const FooterTemplate = ({ headerData, footerData }) => {
  const { socialLinks, tagline } = footerData
  const { menuItems } = headerData
  return (
    <>
      <StyledContainer as="footer">
        <Wrapper
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          mx="auto"
          px={[3, 3, 0]}
          py={5}
        >
          <Branding flexDirection={['column', 'row']}>
            <StyledLogo src={logo} alt="Асоциация Родители" />
            <Box ml={[0, 3]} mt={[3, 0]}>
              <Heading>Асоциация Родители</Heading>
              <Tagline>{tagline}</Tagline>
            </Box>
          </Branding>
          <Box mt={[3, 3, 0]}>
            <Heading>Контакти</Heading>
            <Info>
              София, жк. Яворов, бл. 73, ет. 2<br />
              02/944 17 99
              <br />
              office@roditeli.org
              <br />
            </Info>
            <Heading mt={3}>Удобните спирки</Heading>
            <Info>
              <strong>ж.к. Яворов</strong>
              <br />
              тролей 4, 5, 8, 11 и автобус 84
              <br />
              <strong>Румънско посолство (бул. Ситняково)</strong>
              <br />
              автобус 120, 305, 413
              <br />
              <strong>Румънско посолство (ул. Шипченски пр.)</strong>
              <br />
              трамвай 20, 23
            </Info>
          </Box>
          <Box mt={[3, 3, 0]}>
            <Heading>За нас</Heading>
            <MenuTemplate menu={menuItems} />
          </Box>
          <Box mt={[3, 3, 0]}>
            <Heading>Следете</Heading>
            <MenuTemplate menu={socialLinks} linkType="external" />
          </Box>
        </Wrapper>
      </StyledContainer>
      <Credits color="#818181" backgroundColor="#fff" />
    </>
  )
}

FooterTemplate.propTypes = {
  headerData: PropTypes.object.isRequired,
  footerData: PropTypes.object.isRequired,
}

export const Footer = ({ headerData, footerData }) => {
  if (!headerData && !footerData) {
    return null
  }
  return <FooterTemplate headerData={headerData} footerData={footerData} />
}

Footer.propTypes = FooterTemplate.propTypes
