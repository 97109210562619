import styled, { themeGet, themePx } from 'util/style'
import { Flex, Box } from 'components/Grid'

export const StyledContainer = styled(Box)`
  background: ${themeGet('colors.section.grey')};
  color: ${themeGet('colors.textColor')};
  font-size: ${themePx('fontSizes.0')};
`
export const Wrapper = styled(Flex)`
  max-width: 1150px;
`
export const Branding = styled(Flex)`
  max-width: 440px;
`
export const Menu = styled(Flex)`
  list-style: none;
  margin: 0;
`
export const MenuItem = styled(Box)`
  margin: ${themePx('space.2')} 0 0 0;

  &:first-child {
    margin-top: 0;
  }

  & > a,
  & > a:visited {
    color: ${themeGet('colors.textColor')};
    font-weight: 600;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
  }

  & > a:hover,
  & > a:focus {
    color: ${themeGet('colors.primary')};
  }
`
export const StyledLogo = styled.img`
  width: 80px;
  height: 80px;
`
export const Info = styled(Box)``
export const List = styled.ul`
  list-style: none;
  margin: 0;

  & > li {
    margin: 0;
    margin-left: 10px;
  }
`
export const Heading = styled(Box)`
  font-size: ${themePx('fontSizes.1')};
  font-weight: bold;
  margin-bottom: ${themePx('space.2')};
`
export const Tagline = styled(Box)``
export { Box }
