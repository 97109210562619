import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const CustomLink = ({ linkType, linkURL, children, ...props }) => {
  const file = /\.[0-9a-z]+$/i.test(linkURL)

  if (linkType === 'internal' && !file) {
    return (
      <Link to={linkURL} {...props}>
        {children}
      </Link>
    )
  }
  return (
    <a href={linkURL} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  )
}

CustomLink.propTypes = {
  linkType: PropTypes.string,
  linkURL: PropTypes.string,
  children: PropTypes.node.isRequired,
}
CustomLink.defaultProps = {
  linkType: 'internal',
  linkURL: '',
}
export default CustomLink
