import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'components/Grid'

export const HTMLContent = ({ content, ...props }) => (
  <Box dangerouslySetInnerHTML={{ __html: content }} {...props} />
)

const Content = ({ content, ...props }) => <Box {...props}>{content}</Box>

Content.propTypes = {
  content: PropTypes.node,
}

Content.defaultProps = {
  content: null,
}

HTMLContent.propTypes = Content.propTypes

export default Content
